*{
  box-sizing: border-box;
}

svg:not(:root) {
  width:100%;
  height: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.audio-speed-button {
  border: 1px solid grey;
  font-size: 10px;
  margin: 3px;
  padding: 3px 8px;
  font-weight: bold;
  border-radius: 3px;
}

.selected-audio-speed-button {
  border: 1px solid rgb(182, 182, 182);
  background-color: #223f49;
  color: honeydew;
  font-size: 10px;
  margin: 3px;
  padding: 3px 8px;
  font-weight: bold;
  border-radius: 3px;
}
